<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW AUTO MAINTENANCE DETAILS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="particulars"
              label="Details"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              No.
            </th>
            <th class="text-uppercase">
              Details
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in active_items"
            :key="item.id"
          >
            <td>
              {{ (active_items
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td>
              {{ item.details }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: '',
      category_items: [],
      active_items: [],
      deactive_items: [],
      particulars: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('auto_maintenance_details', ['list_of_auto_maintenance_details', 'register_auto_maintenance_details']),
      initialize_data() {
        this.list_of_auto_maintenance_details()
          .then(response => {
            this.active_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('details', this.particulars.toUpperCase());
          this.register_auto_maintenance_details(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
